// extracted by mini-css-extract-plugin
export var com_cel = "index-module--com_cel--f3070";
export var form_box = "index-module--form_box--3710c";
export var form_box_input = "index-module--form_box_input--477dd";
export var form_box_input_bottom = "index-module--form_box_input_bottom--6764d";
export var form_box_label = "index-module--form_box_label--5d482";
export var form_boxs = "index-module--form_boxs--2a13e";
export var last_information_email_button = "index-module--last_information_email_button--e084d";
export var last_information_section = "index-module--last_information_section--c3cbc";
export var last_information_section_box = "index-module--last_information_section_box--6d48a";
export var last_information_section_box_button_cel = "index-module--last_information_section_box_button_cel--94246";
export var last_information_section_box_content = "index-module--last_information_section_box_content--eec26";
export var last_information_section_box_content_parm = "index-module--last_information_section_box_content_parm--abfeb";
export var last_information_section_box_content_title = "index-module--last_information_section_box_content_title--cee78";
export var last_information_section_box_img = "index-module--last_information_section_box_img--9cf25";
export var selected = "index-module--selected--7a00a";