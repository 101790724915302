import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as bsa from "./index.module.scss"
import {PrismicRichText} from "@prismicio/react";

const BodySectionA = ({data}) => {

    const data_card = data.card_goup;

    return (
        <section className={`body_a_section ${bsa.body_a_section}`}>
            <Container>
                <Row>
                    <Col xxxl={4} xxl={4} xl={4} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        {/*body_section_a_description_a*/}
                        <div className={bsa.body_a_section_title}>
                            <PrismicRichText field={data.body_section_title}/>
                        </div>
                        <div className={bsa.body_a_section_parm}>
                            <PrismicRichText field={data.body_section_a_description_a}/>
                        </div>
                        <div className={`${bsa.body_a_section_parm} ${bsa.body_a_section_parm_bottom}`}>
                            <PrismicRichText field={data.body_section_a_description_b}/>
                        </div>
                    </Col>

                    <Col xxxl={8} xxl={8} xl={8} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        <Row>
                            {
                                data_card.map((item, idx)=>{
                                    return(
                                        <Col className={bsa.body_a_section_cel} xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12}
                                             xs={12} xxs={12}>
                                            <div className={bsa.body_a_section_card}>
                                                {/*Card 3 Logo*/}
                                                <img src={item.card_group_icon.url}/>
                                                {/*Card 3 Title*/}
                                                <PrismicRichText field={item.card_group_title}/>
                                                {/*Card 3 Description*/}
                                                <PrismicRichText field={item.card_group_description}/>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default BodySectionA;
