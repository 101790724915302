import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import * as li from "./index.module.scss"
import Form from "react-bootstrap/Form";
import ArrowRight from "../../../images/common/next3zeros_button_arrow_right.svg";
import {PrismicRichText} from "@prismicio/react";
import axios from "axios";


const isDev = (value) => (process.env.NODE_ENV === "development" ? value : "");

const LatestInformationSection = ({data}) => {

    const [txtEmail, setTxtEmail] = useState("");

    const [success, setSuccess] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [fail, setFail] = useState(false);

    const [progress, setProgress] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFail(false);
        setProgress(true);

        // careerSubmission()
        await axios
            .post("/api/last-information-submission", {
                fields: [
                    { key: "Title", value: "Last Information Email Submission" },
                    { key: "Email", value: txtEmail },
                ],
            })
            .then(() => {
                setSuccess(true);
                setProgress(false);
                setTxtEmail("");
            })


            .catch(() => {
                setFail(true);
                setInterval(() => {
                    setFail(false);
                }, 6000);
            });
        setProgress(false);
    };

    return (
        <section className={`latest_information_section ${li.last_information_section}`}>
            <Container>
                <Row>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className={li.com_cel}>
                        <div className={li.last_information_section_box}>
                            <Row>
                                <Col xxxl={6} xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12} className={li.com_cel}>
                                    <div className={li.last_information_section_box_content}>

                                        {/*Last Information Title*/}
                                        <div className={li.last_information_section_box_content_title}>
                                            <PrismicRichText field={data.title}/>
                                        </div>

                                        {/*Last Information Description*/}
                                        <div className={li.last_information_section_box_content_parm}>
                                            <PrismicRichText field={data.description}/>
                                        </div>

                                        <Form action="" method="post" className="forms"
                                              onSubmit={(e) => {
                                                  handleSubmit(e);
                                              }}
                                        >
                                            <Row>
                                                <Col xxxl={6} xxl={6} xl={6} lg={8} md={8} sm={8} xs={12} xxs={12}  className={`${li.com_cel_input} ${li.com_cel}`}>
                                                    <div className={li.form_box}>
                                                        <Form.Control
                                                            className={li.form_box_input}
                                                             id="txtEmail"
                                                             type="email"
                                                             value={txtEmail}
                                                             onChange={(e) => setTxtEmail(e.target.value)}
                                                        />
                                                        <label htmlFor="name"
                                                               className={`${txtEmail === "" ? null : li.selected} ${li.form_box_label}`}>
                                                            Email
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col xxxl={6} xxl={6} xl={6} lg={4} md={4} sm={4} xs={12} xxs={12}  className={li.com_cel}>

                                                    {/*Last Information Button Text*/}
                                                    <Button className={li.last_information_email_button}
                                                            type="submit"
                                                            disabled={progress || success}
                                                            variant="primary">
                                                        {data.button_text}
                                                        <img src={ArrowRight} />
                                                    </Button>
                                                </Col>
                                                <Col className={li.com_cel}>

                                                    <div className={li.form_boxs}>
                                                        {success && (
                                                            <div className="message text-success"> Thank you for Submission. </div>
                                                        )}
                                                        {fail && (
                                                            <div className="message text-danger">
                                                                {" "}
                                                                Form submission failed. Please try again.{" "}
                                                            </div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                                <Col xxxl={6} xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12}>

                                    {/*Last Information Left Image*/}
                                    <img className={li.last_information_section_box_img}
                                         src={data.side_image}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default LatestInformationSection;
