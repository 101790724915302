import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as wwd from './index.module.scss';
import {PrismicRichText} from "@prismicio/react";
import {graphql, Link, useStaticQuery} from "gatsby";
import get from "lodash/get";

const FutureOpportunitiesSection = ({data}) => {
    const datas = useStaticQuery(graphql`
        query {
            allPrismicGlobals {
                nodes {
                dataRaw
                }
            }
        }
    `)

    const globals = get(datas, "allPrismicGlobals.nodes[0].dataRaw")

    return (
        <section className={`future_opportunities_section ${wwd.future_opportunities_section}`}>
            <Container>
                <div className="animation-container">
                    <div className="circle one"></div>
                    <div className="circle two"></div>
                    <div className="circle three"></div>
                    <div className="circle four"></div>
                </div>
                <Row>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        <div className={wwd.future_opportunities_section_content}>
                            {/*Title*/}
                            <div className={wwd.future_opportunities_section_title}>
                                <PrismicRichText field={data.future_opportunities_title}/>
                            </div>
                            {/*Description*/}
                            <p className={wwd.future_opportunities_section_parm}>
                                {data.fo_description} <strong>
                                                            <Link href={`mailto:${get(globals, "email", "")}`}>
                                                                {data.fo_email}
                                                            </Link>
                                                        </strong>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};


export default FutureOpportunitiesSection;