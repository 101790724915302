import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "gatsby";
import Button from "react-bootstrap/Button";
import next3zeros_card_arrow from "../../../images/we_are_hiring/opening_card/next3zeros_card_arrow.png";
import next3zeros_card_full_time from "../../../images/we_are_hiring/opening_card/next3zeros_card_full_time.png";
import next3zeros_card_location from "../../../images/we_are_hiring/opening_card/next3zeros_card_location.png";
import * as ofs from "./index.module.scss";
import {PrismicRichText} from "@prismicio/react";

const OpeningForSection = ({data}) => {

    const data_card = data.opening_for_section_card;

    return (
        <section className={ofs.opening_for_section} id="areaOpeningForSection">
            <Container className={ofs.opening_for_section_container}>
                <Row>
                    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        {/*Title*/}
                        <div className={ofs.opening_for_section_title}>
                            <PrismicRichText field={data.opening_for_section_titles}/>
                        </div>

                        {/*Description*/}
                        <div className={ofs.opening_for_section_parm}>
                            <PrismicRichText field={data.opening_for_section_description}/>
                        </div>
                    </Col>
                    <Col xxxl={2} xxl={2} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}></Col>
                    <Col xxxl={8} xxl={8} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                        {
                            data_card.map((item, idx)=>{
                                let uid = item.node.uid;
                                return (
                                    <div className={ofs.opening_for_section_card_view}>
                                        <Row>
                                            <Col xxxl={10} xxl={10} xl={10} lg={10} md={10} sm={12} xs={12} xxs={12}>
                                                {/*Card 2 Tile*/}
                                                <p className={ofs.opening_for_section_card_view_title_sub}>
                                                    <PrismicRichText field={item.node.dataRaw.sub_title}/>
                                                </p>
                                                {/*Card 2 Description*/}
                                                <p className={ofs.opening_for_section_card_view_title}>
                                                    <PrismicRichText field={item.node.dataRaw.title}/>
                                                </p>
                                                <ul className={ofs.opening_for_section_card_view_list}>
                                                    <li>
                                                        <img src={next3zeros_card_location}/>
                                                        {/*Full-time*/}
                                                        <PrismicRichText field={item.node.dataRaw.location}/>
                                                    </li>
                                                    <li>
                                                        <img src={next3zeros_card_full_time}/>
                                                        <PrismicRichText field={item.node.dataRaw.working_time}/>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col xxxl={2} xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} xxs={12}
                                                 className={ofs.view_job_cel}>
                                                <p className={ofs.opening_for_section_card_view_job}>
                                                    <Link to={`/we-are-hiring/${uid}`}>
                                                        View job
                                                        <img src={next3zeros_card_arrow}/>
                                                </Link>
                                            </p>
                                        </Col>
                                    </Row>
                                    </div>
                                );
                            })
                        }
                    </Col>
                    <Col xxxl={2} xxl={2} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}></Col>
                </Row>
            </Container>
        </section>
    );
};

export default OpeningForSection;
