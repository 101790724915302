import React, {useState}  from "react";
import Seo from "../../components/seo";
import Layout from "../../components/Layout";
import HeroSection from "../../components/WeAreHiring/HeroSection";
import BodySectionA from "../../components/WeAreHiring/BodySectionA";
import OpeningForSection from "../../components/WeAreHiring/OpeningForSection";
import FutureOpportunitiesSection from "../../components/WeAreHiring/FutureOpportunitiesSection";
import LatestInformationSection from "../../components/WeAreHiring/LatestInformationSection";
import {graphql} from "gatsby";
import get from "lodash/get";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";

const WeAreHiring= ({ data, location }) => {

    const pageData = get(data, "prismicWeAreHiringIndividualPage.dataRaw", {});
    const pageData_1 = get(data, "prismicLastInformationSection.dataRaw", {});
    const pageCareers = get(data, "allPrismicCareersPage.edges", {});

    const hero = {
        hero_title: get(pageData, "hero_title", []),
        hero_description: get(pageData, "hero_description", []),
        hero_button_text: get(pageData, "hero_button_text", []),
        hero_side_image: get(pageData, "hero_side_image.url", [])
    }

    const body_a = {
        body_section_a_description_a: get(pageData, "body_section_a_description_a", []),
        body_section_a_description_b: get(pageData, "body_section_a_description_b", []),
        body_section_title: get(pageData, "body_section_title", []),
        card_goup: get(pageData, "card_goup", []),
    }

    const opening={
        opening_for_section_titles: get(pageData, "opening_for_section_title", []),
        opening_for_section_description: get(pageData, "opening_for_section_description", []),
        opening_for_section_card: pageCareers,
    }

    const future ={
        future_opportunities_title: get(pageData, "future_opportunities_title", []),
        fo_description: get(pageData, "fo_description", []),
        fo_email: get(pageData, "fo_email", []),
    }

    const last ={
        title: get(pageData_1, "title", []),
        side_image: get(pageData_1, "side_image.url", []),
        description: get(pageData_1, "description", []),
        button_text: get(pageData_1, "button_text", []),
    }

    return (
        <Layout location={location} title={''}>
            <HeroSection data={hero}/>
            <BodySectionA data={body_a}/>
            <OpeningForSection data={opening}/>
            <FutureOpportunitiesSection data={future}/>
            <LatestInformationSection data={last}/>
        </Layout>
    );
};

export const Head = ({ data }) => {
    return <Seo title={'We Are Hiring'} description={'excerpt'} />;
};
export default withPrismicPreview(WeAreHiring);

export const query = graphql`
  query seoPersonalInsuranceQuery{
    prismicWeAreHiringIndividualPage{
      dataRaw
    }
    prismicLastInformationSection{
      dataRaw
    }
    
    allPrismicCareersPage {
      edges {
         node {
            dataRaw
            uid
         }
      }
    }
  }
`